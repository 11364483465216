/* Libs */
import './scripts/jqueryLib'
import jquery from '../../node_modules/jquery/dist/jquery.min.js'
import popper from '../../node_modules/popper.js/dist/umd/popper.min.js'
import bootstrap from '../../node_modules/bootstrap/dist/js/bootstrap.min.js'
import slick from '../../node_modules/slick-carousel/slick/slick.min.js'
import parsley from '../../node_modules/parsleyjs/dist/parsley.min.js'

/* Internal Files */
import menu from './scripts/menu.js';
import blocks from './scripts/blocks/blocks.js';

/* loader.js is bundled separately via Webpack. Do not import it here! */
/* lighthouse.js is bundled separately via Webpack. Do not import it here! */
document.addEventListener('DOMContentLoaded', function () {
    menu();
    blocks();

});