
export default function () {

    // Do yo JAHVAHSCRIPT here.
    var isAnimating = false;

    // open first accordion items on load. can't hard code this since its in a cms, der.
    $('.single-accord:first-of-type .b').addClass('current');
    $('.single-accord:first-of-type .h .state').addClass('active');

    $('.single-accord .h').on('click', function (e) {
        // limiting scope of interaction to just one carousel when more than one carousels are on the page.
        var $curAccord = $(this).parent().parent();
        if (!isAnimating) {
            isAnimating = true;
            if ($(this).next().hasClass('current')) {
                $curAccord.find('.single-accord .h .state').removeClass('active');
                $(this).next().removeClass('current').slideUp(function () {
                    isAnimating = false;
                });
            } else {
                $curAccord.find('.single-accord .b').slideUp().removeClass('current');
                $curAccord.find('.single-accord .h .state').removeClass('active');
                $(this).next().addClass('current').slideToggle(function () {
                    isAnimating = false;
                });
                $(this).find('.state').addClass('active');
            }
        }
    });

    var $activeSubMenu;

    $('.menu-item-has-children')
        // expands submenu on mouse hover on desktop
        .mouseenter(function () {
            // collapses any existing open menus
            $('.menu-item-has-children').removeClass('expanded');

            if ($('body').hasClass('menu-open') ) {
                // do nothing
            } else {
                $activeSubMenu = $(this).find('.sub-menu');
                $activeSubMenu.show();
                $(this).addClass('expanded');
            }
        })
        // collapses submenu on mouse leave on desktop
        .mouseleave(function () {
            if ($('body').hasClass('menu-open') ) {
                // do nothing
            } else {
                $activeSubMenu.hide();
                $(this).removeClass('expanded');
            }
        })
        // toggles submenu on click (mostly for mobile taps)
        .click(function () {
            $activeSubMenu = $(this).find('.sub-menu');
            $activeSubMenu.slideToggle();
            $(this).toggleClass('expanded');

            // close all siblings
            $(this).siblings().find('.sub-menu').slideUp();
            $(this).siblings().removeClass('expanded');
        });

    $('.menu-item-has-children > a')
        // neutralizes those top-level links we don't want anyway
        .attr('href','#')
        // toggles submenu on keyboard "click" for a11y
        .click(function (e) {
            e.preventDefault();
            $activeSubMenu = $(this).siblings('.sub-menu');
            $activeSubMenu.slideToggle();
            $(this).parent('li').toggleClass('expanded');

            // close all siblings
            $(this).parent('li').siblings().find('.sub-menu').slideUp();
            $(this).parent('li').siblings().removeClass('expanded');
            e.stopPropagation();
        });


    // ********MODAL STYLE********//
    $('.help-text-icon').on('click', function (e) {
        e.stopPropagation();
        var $clicked = $(this);
        var helpText = $clicked.find('.help-text-container').html();
        $('.help-text-modal .inner').html(helpText);
        $('.help-text-modal').removeClass('hidden');
    });


    $('.help-text-modal .close').on('click', function () {
        $('.help-text-modal').addClass('hidden');
    });


    $('.help-text-modal').on('click', function (e) {
        e.stopPropagation();
        $('.help-text-modal').addClass('hidden');
    });

    // ********MODAL STYLE END********//
    $('.mobile-nav-toggle').on('click', function () {
        if ($('body').hasClass('menu-open')) {
            $('.menu-item-has-children').removeClass('expanded');
            $('.menu-item-has-children .sub-menu').slideUp();
        } else {
            $('.menu-item-has-children:not(.current-menu-ancestor)').removeClass('expanded');
            $('.menu-item-has-children:not(.current-menu-ancestor) .sub-menu').slideUp();
            $('.current-menu-ancestor').addClass('expanded');
            $('.current-menu-ancestor .sub-menu').show();
        }

        $(this).toggleClass('active');
        $('body').toggleClass('menu-open');
        $('.nav').toggleClass('mobile-active');
        $('.top-bar').toggleClass('mobile-active');
    });

    $('[data-toggle="popover"]').popover();

    // Toggle service cards on home page
    $('.service-toggle').first().addClass('active-service');
    $('.service-toggle').on('click', function () {
        $(this).addClass('active-service');
        $('.service-toggle').not(this).removeClass('active-service');
        var service = $(this).attr('href');
        $('.list-of-cards').hide().filter(service).show();
    });

}
